<template>
  <div>
    <!-- 联系我们首图 -->
    <div class="contactBanner">
      <img src="@/assets/img/ContactUs/banner_contactus.png" />
    </div>
    <!-- 地图 -->
    <div class="contactMap">
      <div class="contactMap_title">
        <div>联系方式</div>
        <div>contact</div>
      </div>
      <div>
        <div class="contactMap_info">
          <div>
            <div><img src="@/assets/img/ContactUs/pic-bontact-tel.png" /></div>
            <div>020-3203 9269</div>
          </div>
          <div>
            <div>
              <img src="@/assets/img/ContactUs/pic-bontact-phone.png" />
            </div>
            <div>19924720960</div>
          </div>
          <div>
            <div>
              <img src="@/assets/img/ContactUs/pic-bontact-wechat.png" />
            </div>
            <div>VLimYK</div>
          </div>
        </div>
        <div>
          <baidu-map class="map" :center="markerPoint" :zoom="30">
            <bm-marker
              :position="markerPoint"
              :dragging="true"
              @click="infoWindowOpen"
            >
              <bm-info-window
                :show="show"
                @close="infoWindowClose"
                @open="infoWindowOpen"
              >
                <div class="mapTips">
                  <div>广东微林软件有限公司</div>
                  <br />
                  <div>
                    广州市开发区科学城科研路12号生产力促进中心 205、215、216号
                  </div>
                </div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </div>
    </div>
    <!-- 给我们留言 -->
    <!-- <div class="contactLeave">
      <div class="contactLeave_banner">
        <img src="@/assets/img/ContactUs/bg-ly.png" />
      </div>
      <div class="contactLeave_message">
        <div class="contactLeave_messageTitle">
          <div>给我们留言</div>
          <div></div>
        </div>
        <div class="contactLeave_messageInput">
          <div>
            <div>
              <input type="text" placeholder="姓名" />
            </div>
            <div>
              <input type="text" placeholder="电话" />
            </div>
            <div>
              <input type="text" placeholder="邮箱" />
            </div>
          </div>
          <div>
            <input type="text" placeholder="地址" />
          </div>
          <div>
            <textarea placeholder="输入留言内容"></textarea>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      markerPoint: { lng: 113.448664, lat: 23.175464 },
      show: true,
    };
  },
  methods: {
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
  },
};
</script>

<style scoped>
/* 联系我们-首图 */
.contactBanner img {
  width: 100%;
  height: 300px;
  display: flex;
  object-fit: cover;
}
/* 地图 */
.contactMap {
  width: 1440px;
  margin: 120px auto 160px;
}

.contactMap_title > div:nth-of-type(1) {
  font-size: 40px;
  font-weight: 500;
  color: #47b0b4;
  line-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contactMap_title > div:nth-of-type(2) {
  font-size: 20px;
  font-weight: 400;
  color: #47b0b4;
  line-height: 20px;
  margin-top: 20px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contactMap_info {
  margin: 120px 0 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.contactMap_info img {
  width: 60px;
  height: 60px;
  display: flex;
  object-fit: cover;
  margin-right: 20px;
}
.contactMap_info > div {
  font-size: 30px;
  color: #47b0b4;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.map {
  width: 100%;
  height: 820px;
}
.mapTips {
  width: 300px;
  height: 100px;
}
.mapTips > div:nth-of-type(1) {
  color: #333333;
  font-size: 18px;
  font-weight: 600;
}
.mapTips > div:nth-of-type(2) {
  color: #999999;
  font-size: 16px;
  line-height: 26px;
}
/* 给我们留言 */
.contactLeave {
  position: relative;
}
.contactLeave_banner img {
  width: 100%;
  height: 680px;
  display: flex;
  object-fit: cover;
}
.contactLeave_message {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
}
.contactLeave_messageTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.contactLeave_messageTitle > div:nth-of-type(1) {
  font-size: 40px;
  color: #ffffff;
}
.contactLeave_messageTitle > div:nth-of-type(2) {
  margin-top: 20px;
  width: 60px;
  height: 6px;
  background: #ffffff;
}
/* 给我留言-输入框 */
.contactLeave_messageInput {
  width: 960px;
  margin-top: 60px;
}
.contactLeave_messageInput input {
  outline: none;
  color: #fff;
  border: 1px solid #fff;
  padding: 14px 20px;
  box-sizing: border-box;
  background-color: #ffffff1c;
}
input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #aaaaaa;
}
input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #aaaaaa;
}
input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #aaaaaa;
}
textarea::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #aaaaaa;
}
textarea:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  font-size: 14px;
  color: #fff;
}
textarea::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 14px;
  color: #fff;
}
textarea:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 14px;
  color: #fff;
}
.contactLeave_messageInput > div:nth-of-type(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactLeave_messageInput > div:nth-of-type(1) input {
  width: 280px;
  height: 48px;
  font-size: 24px;
}
.contactLeave_messageInput > div:nth-of-type(2) {
  width: 100%;
  margin: 40px 0;
}
.contactLeave_messageInput > div:nth-of-type(2) input {
  width: 100%;
  height: 48px;
  font-size: 24px;
}
.contactLeave_messageInput > div:nth-of-type(3) {
  width: 100%;
}
.contactLeave_messageInput > div:nth-of-type(3) textarea {
  width: 100%;
  height: 180px;
  font-size: 24px;
  border: 1px solid #fff;
  background-color: #ffffff1c;
  padding: 14px 20px;
  color: #fff;
  outline: none;
  box-sizing: border-box;
}
</style>